<template>
  <v-list v-if="$vuetify.breakpoint.xs">
    <v-data-iterator
      disable-pagination
      hide-default-footer
      :items="items"
      item-key="id"
      sort-by="student"
      disable-sort
      disable-filtering
    >
      <template slot="no-data"
        ><v-card-text class="py-0 text--disabled"
          >keine Daten vorhanden</v-card-text
        ></template
      >
      <v-list-item
        v-for="item in items"
        :key="item.name"
        @click="showDetails(item)"
      >
        <v-list-item-avatar v-if="!hideStudent"
          ><PortraitImage :value="item.student"></PortraitImage
        ></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><PersonName :value="item.student" v-if="!hideStudent"></PersonName>
          </v-list-item-title>

          <v-list-item-subtitle>
            <DateValue :value="item.startDate" long /><span
              v-if="item.startTime && item.startTime != '23:59'"
              >, {{ item.startTime }}</span
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            bis <DateValue :value="item.endDate" long /><span
              v-if="item.endTime && item.endTime != '23:59'"
              >, {{ item.endTime }}</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar>
          <v-icon outlined :color="statusColor(item.status)">
            {{ statusIcon(item.status) }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-avatar>
          <v-icon>
            {{ categoryIcon(item.category) }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-action>
          <v-chip> {{ item.lessonCount }}</v-chip>
        </v-list-item-action>
      </v-list-item>
    </v-data-iterator>
  </v-list>
  <v-data-table
    v-else
    disable-pagination
    hide-default-footer
    :headers="headers"
    :items="itemsSorted"
    item-key="id"
    @click:row="(el) => showDetails(el)"
    :item-class="() => 'clickable'"
    disable-filtering
    disable-sort
  >
    <template v-slot:item.student="{ item }">
      <PersonItem :value="item.student" small />
    </template>
    <template v-slot:item.startDate="{ item }">
      <DateValue :value="item.startDate" long />
      <span v-if="item.startTime && item.startTime != '00:00'"
        >, {{ item.startTime }}</span
      >
    </template>
    <template v-slot:item.endDate="{ item }">
      <DateValue :value="item.endDate" long /><span
        v-if="item.endTime && item.endTime != '23:59'"
        >, {{ item.endTime }}</span
      >
    </template>
    <template v-slot:item.status="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            outlined
            v-bind="attrs"
            v-on="on"
            :color="statusColor(item.status)"
          >
            {{ statusIcon(item.status) }}
          </v-icon>
        </template>
        <span>{{ item.status.description }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.category="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">
            {{ categoryIcon(item.category) }}
          </v-icon>
        </template>
        <span>{{ item.category.description }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import PersonItem from "common/components/PersonItem.vue";

import {
  justificationCategoryIcon,
  justificationStatusColor,
  justificationStatusIcon,
} from "common/utils/icons.js";

export default defineComponent({
  components: { DateValue, PersonItem, PersonName, PortraitImage },
  props: { items: [], hideStudent: { type: Boolean, default: false } },
  data() {
    return {};
  },
  computed: {
    headers() {
      let buildHeaders = [
        {
          text: "von",
          value: "startDate",
        },
        {
          text: "bis",
          value: "endDate",
        },
        {
          text: "Kategorie",
          value: "category",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        {
          text: "Lektionen",
          value: "lessonCount",
          align: "center",
        },
      ];
      if (!this.hideStudent) {
        buildHeaders.unshift({
          text: "",
          value: "student",
        });
      }
      if (this.$_hasRole("teacher")) {
        buildHeaders.push({
          text: "Kommentar KL",
          value: "comments",
        });
      }
      return buildHeaders;
    },
    itemsSorted() {
      return [...this.items].sort((a, b) => {
        const aValue = a.startDate + a.startTime;
        const bValue = b.startDate + b.startTime;
        return aValue.localeCompare(bValue);
      });
    },
  },
  methods: {
    showDetails(item) {
      if (this.$_hasRole("student")) {
        this.$router.push({
          name: "JustificationStudentDetail",
          params: { id: item.student.id, justificationId: item.id },
        });
      } else if (this.$route.name == "AbsencesPending") {
        this.$router.push({
          name: "JustificationPendingDetail",
          params: { justificationId: item.id },
        });
      } else {
        this.$router.push({
          name: "JustificationOverviewDetail",
          params: { id: item.student.id, justificationId: item.id },
        });
      }
    },
    statusColor: justificationStatusColor,
    statusIcon: justificationStatusIcon,
    categoryIcon: justificationCategoryIcon,
  },
});
</script>
