<template>
  <div v-if="overview">
    <v-card flat :loading="loadingOverview" class="mb-4">
      <div class="d-flex flex-no-wrap">
        <v-avatar class="ma-3" size="90">
          <PortraitImage :value="overview.student" large />
        </v-avatar>
        <div>
          <v-card-title class="text-h5"
            ><PersonName :value="overview.student"
          /></v-card-title>

          <v-card-subtitle
            >{{ overview.student.schoolClass.code }}<br />
            Alter: {{ overview.student.age }} Jahre</v-card-subtitle
          >

          <v-chip-group column>
            <v-chip color="error" outlined
              >offen: {{ overview.pendingLessons }}</v-chip
            >

            <v-chip color="success" outlined
              >entschuldigt: {{ overview.excusedLessons }}</v-chip
            >

            <v-chip color="danger" outlined
              >unentschuldigt: {{ overview.unexcusedLessons }}</v-chip
            >

            <v-chip color="info" outlined
              >bezogene Halbtage: {{ overview.halfDays }}</v-chip
            >
          </v-chip-group>
        </div>
      </div>
    </v-card>

    <v-alert
      text
      type="warning"
      :prominent="!$vuetify.breakpoint.xs"
      v-if="term && term.current && overview.warningLevel == 1"
    >
      im aktuellen Semester gibt es bereits
      {{ overview.absentLessons }} Absenzen (inklusive pendenter Abwesenheiten)
    </v-alert>
    <v-alert
      text
      type="error"
      :prominent="!$vuetify.breakpoint.xs"
      v-if="term && term.current && overview.warningLevel == 2"
    >
      im aktuellen Semester gibt es bereits
      {{ overview.absentLessons }} Absenzen (inklusive pendenter Abwesenheiten)
    </v-alert>
    <v-toolbar flat dense>
      <v-tabs centered v-model="tab">
        <v-tab
          ><v-badge v-if="pendingCount > 0" :content="pendingCount" inline
            >Pendenzen</v-badge
          ><span v-else>Pendenzen</span></v-tab
        >
        <v-tab>Kalender</v-tab>
        <v-tab>Heatmap</v-tab>
        <v-tab>Unentschuldigte</v-tab>
        <v-tab>Archiv</v-tab>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item key="0" :transition="false">
        <v-container>
          <v-card
            class="mb-4"
            :loading="loadingJustifications"
            v-if="$_hasRole('student')"
          >
            <v-system-bar
              ><v-badge
                dot
                left
                inline
                :value="justificationsStudent.length > 0"
                >Begründungen pendent bei mir</v-badge
              ></v-system-bar
            >
            <JustificationList :items="justificationsStudent" hideStudent />
          </v-card>

          <v-card class="mb-4" :loading="loadingJustifications">
            <v-system-bar v-if="$_hasRole('teacher')">
              <v-badge dot left inline :value="justificationsTeacher.length > 0"
                >Begründungen pendent bei mir</v-badge
              >
            </v-system-bar>
            <v-system-bar v-else>
              <v-badge dot left inline :value="false"
                >Begründungen pendent bei Klassenlehrperson</v-badge
              >
            </v-system-bar>
            <JustificationList :items="justificationsTeacher" hideStudent />
          </v-card>

          <v-card
            class="mb-4"
            outlined
            :loading="loadingJustifications"
            v-if="!$_hasRole('student')"
          >
            <v-system-bar>
              <v-badge dot left inline :value="false"
                >Begründungen pendent bei Schüler:in</v-badge
              ></v-system-bar
            >
            <JustificationList :items="justificationsStudent" hideStudent />
          </v-card>

          <v-card class="mb-4" :loading="loadingAbsences">
            <v-system-bar
              ><v-badge
                dot
                left
                inline
                :value="$_hasRole('student') && absencesPending.length > 0"
                >Absenzen ohne Begründung</v-badge
              ></v-system-bar
            >
            <AbsenceList
              :items="absencesPending"
              @updated="fetchAbsences"
              hideStudent
              :actions="['delete', 'unexcuse']"
            />
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="1" :transition="false">
        <v-container>
          <v-card class="mb-4" :loading="loadingAbsences">
            <v-system-bar>Kalender</v-system-bar>
            <v-toolbar dense flat>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="$refs.calendar.prev()"
                  >
                    <v-icon>mdi-chevron-double-left</v-icon>
                  </v-btn>
                </template>
                <span>früher</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="$refs.calendar.next()"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </v-btn>
                </template>
                <span>später</span>
              </v-tooltip>

              <v-menu
                v-model="dateMenu"
                offset-y
                :nudge-left="40"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined text v-bind="attrs" v-on="on"
                    >{{ month }}
                  </v-btn>
                </template>
                <v-date-picker
                  :min="term.startDate"
                  :max="term.endDate"
                  color="primary"
                  :value="focus"
                  @input="
                    (value) => {
                      dateMenu = false;
                      focus = value + '-01';
                    }
                  "
                  type="month"
                  locale="de-CH"
                  first-day-of-week="1"
                  show-week
                  :locale-first-day-of-year="4"
                >
                </v-date-picker>
              </v-menu>
            </v-toolbar>
            <v-calendar
              ref="calendar"
              :events="events"
              :weekdays="[1, 2, 3, 4, 5, 6, 0]"
              :locale-first-day-of-year="4"
              locale="de-CH"
              type="month"
              v-model="focus"
              @click:event="(el, index) => showDetails(el)"
              :start="term.startDate"
              :end="term.endDate"
            >
            </v-calendar>
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="2" :transition="false">
        <v-container>
          <v-card class="mb-4" :loading="loadingJustifications">
            <v-system-bar>Heatmap</v-system-bar>
            <JustificationHeatmap
              :id="id"
              :term="term"
              :schoolClass="overview.student.schoolClass"
            />
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="3" :transition="false">
        <v-container>
          <v-card class="mb-4" :loading="loadingAbsences">
            <v-system-bar>Untentschuldigte Absenzen</v-system-bar>
            <AbsenceList
              :items="absencesUnexcused"
              hideStudent
              :schoolClass="overview.student.schoolClass"
              :actions="['reactivate']"
            />
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="4" :transition="false">
        <v-container>
          <v-card class="mb-4" :loading="loadingJustifications">
            <v-system-bar>Archiv</v-system-bar>
            <JustificationList
              :items="justificationsArchived"
              hideStudent
              :schoolClass="overview.student.schoolClass"
            />
          </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <router-view
      v-if="overview"
      @updated="fetchData()"
      :student="overview.student"
      :schoolClass="overview.student.schoolClass"
    />
    <v-dialog v-model="dialog" width="auto">
      <v-card>
        <v-system-bar window>
          pendente Absenzen <v-spacer></v-spacer
          ><v-btn icon @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-system-bar
        >

        <AbsenceList
          :items="absencesDialog"
          hideStudent
          @updated="absenceDeleted"
          :actions="['delete']"
        />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> schliessen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AbsenceList from "common/components/AbsenceList.vue";
import JustificationList from "common/components/JustificationList.vue";
import JustificationHeatmap from "common/components/Absences/JustificationHeatmap.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";

import { dateDiff, formatMonth, today } from "common/utils/date";

import { justificationStatusColor } from "common/utils/icons.js";

export default defineComponent({
  components: {
    AbsenceList,
    JustificationList,
    JustificationHeatmap,
    PersonName,
    PortraitImage,
  },
  props: { id: { type: Number, default: 0 }, term: { startDate: 0 } },
  data() {
    return {
      dateMenu: false,
      dialog: false,
      focus: "",
      loadingAbsences: false,
      loadingJustifications: false,
      loadingOverview: false,
      month: "",
      overview: null,
      absencesPending: [],
      absencesUnexcused: [],
      eventIndex: -1,
      justificationsArchived: [],
      justificationsStudent: [],
      justificationsTeacher: [],
      today: null,
      tab: 0,
    };
  },
  computed: {
    absencesDialog() {
      if (this.eventIndex < 0 || !this.events[this.eventIndex]) {
        return [];
      }
      return this.events[this.eventIndex].absences;
    },
    canEdit() {
      return this.$_isClassTeacherOf(this.overview.student);
    },
    events() {
      const items = [];
      for (const item of this.absencesPending) {
        const absence = items.find((el) => el.date == item.date);
        if (absence) {
          absence.count = absence.count + 1;
          absence.name = "Absenzen " + absence.count + "L";
          absence.absences.push(item);
        } else {
          const eventStart = new Date(item.date + "T" + item.startTime);
          const eventEnd = new Date(item.date + "T" + item.endTime);

          items.push({
            name: "Absenz 1L",
            date: item.date,
            start: eventStart,
            end: eventEnd,
            color: this.age(item) > 8 ? "warning" : "info",
            timed: false,
            id: 0,
            count: 1,
            absences: [item],
          });
        }
      }

      for (const item of this.absencesUnexcused) {
        const absence = items.find((el) => el.date == item.date);
        if (absence) {
          absence.count = absence.count + 1;
          absence.name = "Unentschuldigte " + absence.count + "L";
          absence.absences.push(item);
        } else {
          const eventStart = new Date(item.date + "T" + item.startTime);
          const eventEnd = new Date(item.date + "T" + item.endTime);

          items.push({
            name: "Unentschuldigte 1L",
            date: item.date,
            start: eventStart,
            end: eventEnd,
            color: "danger",
            timed: false,
            id: 0,
            count: 1,
            absences: [item],
          });
        }
      }

      for (const item of this.justificationsStudent) {
        const eventStart = item.startTime
          ? new Date(item.startDate + "T" + item.startTime)
          : new Date(item.startDate);
        const eventEnd = item.endTime
          ? new Date(item.endDate + "T" + item.endTime)
          : new Date(item.endDate);

        items.push({
          name: "Entschuldigung",
          start: eventStart,
          end: eventEnd,
          color: justificationStatusColor(item.status),
          timed: false,
          id: item.id,
        });
      }

      for (const item of this.justificationsTeacher) {
        const eventStart = item.startTime
          ? new Date(item.startDate + "T" + item.startTime)
          : new Date(item.startDate);
        const eventEnd = item.endTime
          ? new Date(item.endDate + "T" + item.endTime)
          : new Date(item.endDate);

        items.push({
          name: "Entschuldigung",
          start: eventStart,
          end: eventEnd,
          color: justificationStatusColor(item.status),
          timed: false,
          id: item.id,
        });
      }
      for (const item of this.justificationsArchived) {
        if (item.status.code != "deleted" && item.status.code != "unexcused") {
          const eventStart = item.startTime
            ? new Date(item.startDate + "T" + item.startTime)
            : new Date(item.startDate);
          const eventEnd = item.endTime
            ? new Date(item.endDate + "T" + item.endTime)
            : new Date(item.endDate);

          items.push({
            name: "Entschuldigung " + item.lessonCount + "L",
            start: eventStart,
            end: eventEnd,
            color: justificationStatusColor(item.status),
            timed: false,
            id: item.id,
          });
        }
      }
      return items;
    },
    pendingCount() {
      if (this.$_hasRole("student")) {
        const absencesCount = Math.min(this.absencesPending.length, 1);
        return this.justificationsStudent.length + absencesCount;
      }
      return this.justificationsTeacher.length;
    },
  },
  watch: {
    focus() {
      this.month = formatMonth(this.focus);
    },
    id(oldValue, newValue) {
      if (oldValue != newValue) {
        this.fetchData();
      }
    },
    term() {
      this.fetchData();
    },
  },

  methods: {
    async absenceDeleted() {
      if (this.absencesDialog.length == 1) {
        this.dialog = false;
      }
      await this.fetchAbsences();
    },
    async fetchAbsences() {
      this.loadingAbsences = true;

      this.absencesPending = await this.apiList({
        resource: "absence/absence",
        query: `student=${this.id}&pending`,
      });
      this.absencesUnexcused = await this.apiList({
        resource: "absence/absence",
        query: `student=${this.id}&unexcused&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });

      this.loadingAbsences = false;
    },
    async fetchJustifications() {
      this.loadingJustifications = true;

      this.justificationsTeacher = await this.apiList({
        resource: "absence/justification",
        query: `status=pendingTeacher&student=${this.id}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });

      this.justificationsStudent = await this.apiList({
        resource: "absence/justification",
        query: `status=pendingStudent&student=${this.id}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });

      this.justificationsArchived = await this.apiList({
        resource: "absence/justification",
        query: `status=archived&student=${this.id}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
      this.loadingJustifications = false;
    },
    async fetchOverview() {
      this.loadingOverview = true;

      this.overview = await this.apiList({
        resource: "absence/overview",
        query: `student=${this.id}&term=${this.term.id}`,
      });

      this.loadingOverview = false;
    },
    async fetchData() {
      if (!this.id || !this.term) return;
      await this.fetchOverview();
      await this.fetchAbsences();
      await this.fetchJustifications();
    },

    showDetails(el) {
      if (el.event.id) {
        if (this.$_hasRole("student")) {
          this.$router.push({
            name: "JustificationStudentDetail",
            params: { id: this.id, justificationId: el.event.id },
          });
        } else {
          this.$router.push({
            name: "JustificationOverviewDetail",
            params: { id: this.id, justificationId: el.event.id },
          });
        }
      } else if (el.event.absences) {
        this.eventIndex = this.events.findIndex(
          (event) => event.date == el.event.date
        );
        this.dialog = true;
      }
    },

    age(item) {
      return dateDiff(item.date, this.today);
    },
  },
  created() {
    this.focus = today();
    this.today = today();
    this.fetchData();
  },
});
</script>
